/*
 * Placeholder dashboard ideas
 */

.placeholders {
  margin-bottom: 30px;
  text-align: center;
}
.placeholders h4 {
  margin-bottom: 0;
}
.placeholder {
  margin-bottom: 20px;
}

.move-left-hide {
  transform: translateX(-250px);
  transition: all 1s ease-in;
  width: 0%;
}

.move-left-full {
    transform: translateX(-250px);
  transition: all 1s ease-in;
    width: 100%;
}

.wrapper #sidebar-toggle {
  background: #DF314D;
  border-radius: 3px;
  display: block;
  position: relative;
  padding: 10px 7px;
  float: left;
}

.swipe-area {
  position: absolute;
  width: 50px;
  left: 0;
  top: 0;
  height: 100%;
  background: #f3f3f3;
  z-index: 0;
}