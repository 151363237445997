.grid {
  width: 100%;
  height: 80%;
}
.ui-grid-row:nth-child(even) .ui-grid-cell {
  background-color: #f3f3f3;
}

.ui-grid-row:nth-child(odd) .ui-grid-cell {
  background-color: #fdfdfd;
}

.row {
  margin: 0;
}